#page {
    padding: 50px 0;

    h1 {
        font-size: 46px;
        text-align: center;
    }

    h3 {
        font-size: 24px;
    }

    table {
        margin-bottom: 15px;

        tr {
            border: none !important;

            &:nth-child(even) {
                background: #f5f5f5;
            }
        }
    }
}

.content-top {
    background-image: url('../img/bg-vorm-roze.jpg');
    background-size: cover;
    padding-top: 40px;
}

img {
    width: 100%;
    // box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.5);
}

h2 {
    line-height: 1.1;
    color: $paars;
}

h1 {
    line-height: 1.1;

    &.title {
        color: $paars;
        line-height: 1.1;
    }
}

// h4 {
//     position: relative;

//     &.line {
//         margin-top: 10px;
//         margin-left: 15px;
//         color: #000;

//         &::before {
//         content: "";
//         position: absolute;
//         left: -15px;
//         top: 0;
//         display: block;
//         height: 100%;
//         width: 5px;
//         background-color: $roze;
//         border-radius: 5px;
        
//         }
//     }
// }

#werkwijze-page {
    .werkwijze-title {
        font-size: 2em;
    }  
}

ul{
    li {
        line-height: 36px;
        font-size: 18px
    }
}

h5 {
    font-size: 20px;
    color: $roze;
}

.btn-wrapper {
    margin-top: 40px;
}

.btn-blue {
    margin: 10px;

    &.static {
        display: block;
        border-radius: 0;
    }
}

.img-background {
    background-size: cover;
    background-position: top;
    max-height: 660px;
    height: 300px;

    &.werkwijze {
        background-image: url('../img/bg-werkwijze.jpg');
    }

    &.wiewijzijn {
        background-image: url('../img/bg-wiewijzijn.jpg');
    }
}

.aanpak-wrapper {
    background-image: url('../img/bg-vorm-blauw.jpg');
    background-size: cover;
    background-position: top;

    .pic-blok {
        text-align: center;

        .icons {
            font-size: 80px;
        }
    }

    h4, p {
        text-align: center;
    }

    h4 {
        margin-top: 65px;
        color: $paars;
    }

    p {
        margin-bottom: 65px;
        font-weight: bold;
    }
}

.besparing-wrapper {
    margin-bottom: 50px;
}

@media (min-width: $screen-sm-min) {
    .img-background {
        background-position: center;
    }

    h4 {
        &.line {
            font-size: 20px;
            margin-top: 40px;
        }
    }
}

@media (min-width: $screen-md-min) {
    .btn-blue {
        &.static {
            width: 400px;
            margin: 20px;
        }
    }
    
    .btn-wrapper {
        margin-top: 0;
    }

    h4 {
        &.line {
            font-size: 43px;
            margin-top: 10px;
        }
    }
}

@media (min-width: $screen-lg-min) {

    .content-top {
        padding-top: 50px;
    }

    .img-background {
        height: 700px;
        background-position: center;
        margin-bottom: 100px;
    }

    .content-top-wrapper {
        padding: 0;
    }

    h1 {
        &.title {
            font-size: 58px;
        }
    }

    h2 {
        font-size: 58px;
    }

    #werkwijze-page {
        .werkwijze-title {
            font-size: 58px;
        }
    }

    h3 {
        font-size: 43px;
    }

    h4 {
        font-size: 43px;
    }
    
    h5 {
        font-size: 28px;
        margin: 0;
    }

    p {
        font-size: 18px;
        line-height: 36px;

        &.p1 {
            margin-right: 100px;
        }
    }

    .besparing-wrapper {
        margin-bottom: 100px;
    }
}