header {
    padding: 10px 0;
    position: relative;

    .header-logo {
        img {
            height: 50px;
            width: auto;
            margin: 5px 0 0;
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
        }
    }

    .header-btn-wrapper {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);

        line-height: 35px;
        text-align: center;

        a {
            display: block;
            height: 35px;
            width: 35px;
            border: 1px solid #000;
            color: #000;
            border-radius: 35px;
            font-size: 20px;
            transition: all 0.5s ease;
            text-decoration: none;

            &:hover {
                background-color: $blauw;
                border-color: $blauw;
                color: #fff;
            }
        }
    }

    nav {
        display: none;

        ul {
            margin: 0;
            padding: 0;

            li {
                position: relative;
                list-style: none;
                line-height: 2;
                font-weight: 400;
                font-size: 16px;

                &:hover {
                    > a {
                        color: $blauw_licht;
                    }
                }

                ul {
                    display: none;
                    margin-left: 15px;
                }

                a {
                    color: $blauw_donker;
                    text-transform: uppercase;
                    text-decoration: none;

                    &.btn-blue {
                        display: block;
                        margin: 0;
                        margin-top: 15px;
                        padding: 5px 15px;
                        font-size: 16px;
                    }

                    &.active {
                        font-weight: bold;
                        color: $groen;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    header {
        .header-logo {
            img {
                height: 70px;
                width: auto;
                margin: 5px 0 0;
                box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    header {
        .header-logo {
            img {
                height: 90px;
                width: auto;
                margin: 5px 0 0;
                box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
            }
        }

        .header-btn-wrapper {
            display: none;
        }

        nav {
            position: absolute;
            display: block;
            top: 26px;
            right: 0;

            ul {
                li {
                    display: inline;
                    margin: 0 10px 10px;
                    display: inline-block;

                    &:hover {
                        > ul {
                            display: block;
                            padding: 0; //haal weg als submenu aan moet.
                        }
                    }

                    a {
                        &.btn-blue {
                            margin-top: 0;
                        }
                    }

                    ul {
                        position: absolute;
                        display: none;
                        background-color: white;
                        left: -15px;
                        z-index: 5;
                        line-height: 2;
                        margin-left: 0;
                        padding: 40px 20px 10px 15px;

                        li {
                            white-space: nowrap;
                            margin: 0;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    header {

        .header-logo {
            img {
                height: 80px;
                width: auto; 
                margin: 10px 0 0;
                box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
            }
        }

        nav {
            top: 35%;

            ul {
                li {
                    margin: 0 30px;

                    ul {
                        left: -20px;
                        padding: 80px 40px 20px 20px;
                    }
                }
            }
        }
    }
}