#homepage {
    .wiewezijn-wrapper {
        background-image: url('/img/bg-wie-zijn-wij2.png');
        background-size: cover;
        padding-top: 30px;
        padding-bottom: 20px;
    
        h1 {
            line-height: 1.1;
            padding-bottom: 40px;
            position: relative;
            color: $blauw_donker;
    
            &:after {
                content: ' ';
                position: absolute;
                border-radius: 2px;
                background-image: url('/img/dotted1.png') ;
                background-repeat: no-repeat;
                height: 6px;
                width: 212px;
                left: 0;
                bottom: 10px;
            }
        }
    
        h4 {
            text-align: center;
            margin: 10px;
        }
    
        .btn-pink {
            margin: 10px 0;
        }
    
        img {
            width: 100%;
            // box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.5);
        }
    }
    
    .werkwijze-wrapper {
        background: url('../img/bg-specialisten.jpg') no-repeat bottom center;
        background-size: cover;
        text-align: center;
        color: #fff;
        
        h2 {
            position: relative;
            color: #fff;
            padding-bottom: 40px;

            &:after {
                content: ' ';
                position: absolute;
                border-radius: 2px;
                background-image: url('/img/dotted2.png') ;
                background-repeat: no-repeat;
                height: 6px;
                left: 50%;
                bottom: 10px;
                transform: translate(-50%, 0);
                width: 212px;
            }
        }
    
        .pic-blok {
            margin: 30px 0 10px;
            border-radius: 25px;
            padding: 20px;
            background: #fff;
    
            .icon-wrapper {
                padding-top: 20px;
                color: $blauw_licht;
                font-size: 80px;
            }

            img{
                width: auto;
                height: 200px;
                margin: 15px;
            }
    
            .pic-text {
                font-size: 18px;
                color: #000;
                margin: 0;
            }
        }
    
        .btn-blue {
            margin-bottom: 60px;
        }
    }
    
    .betalen-wrapper {
        background: url('/img/verzuim-en-reintegratie.jpg') no-repeat center right;
        background-size: cover;
        padding: 50px 0;
        color: #000;
    
        h2 {
            padding-bottom: 40px;
            position: relative;
            color: $blauw_donker;


            &:after {
                content: ' ';
                position: absolute;
                border-radius: 2px;
                background-image: url('/img/dotted1.png') ;
                background-repeat: no-repeat;
                height: 6px;
                width: 212px;
                left: 0;
                bottom: 10px;
            }
        }
    
        img {
            margin-top: 20px;
            width: auto;   
            float: right;
        }
    }
    
    .testimonials-wrapper {
        margin-bottom: 20px;

        h2 {
            position: relative;
            padding-bottom: 40px;
            color: $blauw_donker;
            text-align: center;

            &:after {
                content: ' ';
                position: absolute;
                border-radius: 2px;
                background-image: url('/img/dotted1.png') ;
                background-repeat: no-repeat;
                height: 6px;
                width: 212px;
                left: 50%;
                bottom: 10px;
                transform: translate(-50%, 0);
            }
        }

        .slick-arrow {
            position: absolute;
            top: 30%;
            transform: translateY(-50%);
            font-size: 50px;
            // font-size: 200px;
            color: $blauw;
            z-index: 2;

            &.next {
                right: 0;
            }
        }
    
        .testimonial {
            margin: 20px 0;

            p {
                font-size: 20px;
                color: $blauw_licht;
                max-width: 825px;
                text-align: center;
                margin: 0 auto;
                line-height: 25px;
            }
    
            &:focus {
                outline: 0;
            }
        }

        .slick-dots {
            text-align: center;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                
                button {
                    display: block;
                    padding: 0;
                    appearance: none;
                    border: 3px solid $blauw;
                    border-radius: 50%;
                    font-size: 0;
                    height: 15px;
                    width: 15px;
                    margin: 0 15px;
                    outline: 0;

                    transition: all 300ms ease;
                }
                &.slick-active {
                    button {
                        background: $blauw;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    #homepage {
        .testimonials-wrapper {
            .slick-arrow {
                font-size: 100px;
            }
            .testimonial {
                p {
                    font-size: 35px;
                    line-height: 45px;
                }
        
                &:focus {
                    outline: 0;
                }
            }

        }
    }
}

@media (min-width: $screen-md-min) {
    #homepage {
        .testimonials-wrapper {
            .slick-arrow {
                font-size: 150px;
            }
        }
        .wiewezijn-wrapper {
            padding-top: 50px;
            
            h4 {
                text-align: left;
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    #homepage {

        .wiewezijn-wrapper {
            padding-top: 80px;
            padding-bottom: 60px;
            font-size: 18px;
    
            .btn-pink {
                margin-top: 30px;
            }
    
            h1 {
                font-size: 50px;
                text-align: center;
                color: $paars;
            }
    
            h4 {
                position: relative;
                font-size: 28px;
                margin-top: 30px;
                margin-left: 15px;
    
                &::before {
                    content: "";
                    position: absolute;
                    left: -15px;
                    top: 0;
                    display: block;
                    height: 100%;
                    width: 5px;
                    background-color: $roze;
                    border-radius: 5px;
                }
            }
        }
    
        .werkwijze-wrapper {
            h2 {
                margin-top: 50px;
                margin-bottom: 30px;
                font-size: 58px;
            }
    
            .subtext {
                margin: 0 200px 80px;
            }
        
            p {
                font-size: 18px;
            }
        
            .pic-blok {
                // padding: 0 40px 50px;
            }
    
            .btn-blue {
                margin-bottom: 45px;
            }
        }
    
        .betalen-wrapper {
            padding: 70px 0;
    
            h2 {
                font-size: 45px;
            }
        
            .paragraph-wrapper {
                font-size: 18px;;
                line-height: 36px;
            }
        }
    
        .testimonials-wrapper {
    
            .testimonial {
                margin-bottom: 75px;
            }
    
            h2 {
                margin-top: 75px;
                font-size: 55px;

                
            }
    
            .name {
                font-size: 24px;
            }
    
            .role {
                font-size: 18px;
                margin-bottom: 55px;
            }
    
            a {
                font-size: 24px;
            }
    
            h4 {
                position: relative;
                font-size: 42px;
                margin-bottom: 60px;
    
                &::before {
                    content: "";
                    position: absolute;
                    left: -15px;
                    top: 0;
                    display: block;
                    height: 100%;
                    width: 5px;
                    background-color: $roze;
                    border-radius: 5px;
                }   
            }

            .slick-arrow {
                font-size: 200px;
            }
        }
    }
}