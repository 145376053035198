footer {
    background: url("/img/bg-footer.png") left center no-repeat;
    // background-size: cover;
    background-color: #2b2b67;
    text-align: center;
    color: #FFF;
    padding-top: 30px;
    padding-bottom: 30px;

    .row {
        .center-img {
            img {
                height: 70px;
                width: auto;
                
            }
        }
    }

    ul {
        padding: 0;

        li {
            list-style: none;

            a {
                text-decoration: none;
                color: #FFF;
            }
        }
    }

    p {
        line-height: 1.5;
    }

    .info-list {
        li {
            line-height: 1.5;
        }
    }

    .pages-list {
        li {
            line-height: 1.8;

            a {
                .icon-angle-up {
                    display: none;
                    transform: rotate(90deg);
                }
            }
        }
    }
}


.footer-bottom {
    background: #2e2e84;

    .copyright {
        p {
            font-size: 13px;
            color: #fff;
    
            a {
                color: #fff;
    
                &:visited {
                    color: #fff;
                }
            }
        }
        text-align: center;
    }
}

@media (min-width: $screen-md-min) {
    footer {
        text-align: left;

         .row {
            position: relative;

            .center-img {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .pages-list {
            li {
                a {
                    .icon-angle-up {
                        display: inline-block;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    footer {
        text-align: left;
        font-size: 20px;
        padding: 60px 0;
    }

    .copyright {
        p {
            font-size: 15px;   
        }
    }
}

