@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
@import url('https://fonts.googleapis.com/css?family=Nunito:400,500,700,800');

@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?kb6jrh');
  src:  url('fonts/icomoon.eot?kb6jrh#iefix') format('embedded-opentype'),
  url('fonts/icomoon.svg?kb6jrh#icomoon') format('svg'),
  url('fonts/icomoon.woff?kb6jrh') format('woff'),
  url('fonts/icomoon.ttf?kb6jrh') format('truetype');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-euroteken:before {
  content: "\e90b";
}
.icon-mapje:before {
  content: "\e90c";
}
.icon-crosshair:before {
  content: "\e906";
}
.icon-vergrootglas:before {
  content: "\e908";
}
.icon-controls:before {
  content: "\e909";
}
.icon-arrow-down:before {
  content: "\e902";
}
.icon-logo-wga:before {
  content: "\e900";
}
.icon-uitspraak:before {
  content: "\e901";
}
.icon-mail:before {
  content: "\e905";
}
.icon-phone:before {
  content: "\e904";
}
.icon-menu:before {
  content: "\e903";
}
.icon-arrow-left:before {
  content: "\e907";
}
.icon-arrow-right:before {
  content: "\e90a";
}
