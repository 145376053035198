#klachten {
    .no-mar {
        h2,
        h4 {
            margin-bottom: 0;

        }
        p {
            margin-top: 0;
        }
    }
    h2 {
        color: $blauw_donker;

        &.title {
            position: relative;
            padding-bottom: 40px;

            &:after {
                content: ' ';
                position: absolute;
                border-radius: 2px;
                background-image: url('/img/dotted1.png') ;
                background-repeat: no-repeat;
                
                height: 6px;
                width: 30%;
                left: 0;
                bottom: 10px;
            }

            &.center {
                text-align: center;

                &:after {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    .btn-blue{
        margin-left: 40%;
        margin-bottom: 50px;
    }

    .background-wrapper {
        position: relative;
        background: url('../img/klachten_foto.jpg') no-repeat top left;
        background-size: cover;
        max-height: 550px;
    }

    .content-top-wrapper {
        h4 {
            color: $groen;
            font-size: 28px;
        }
    }

    .power {
        background: url('/img/bg-vorm-blauw.jpg') no-repeat center center;
        background-size: cover;
        text-align: center;
        padding: 30px 0;

        h2,
        p {
            color: #fff;
        }
        h2 {
            position: relative;
            padding-bottom: 40px;
            font-size: 35px;
            
            &:after {
                content: ' ';
                position: absolute;
                border-radius: 2px;
                background-image: url('/img/dotted1.png') ;
                background-repeat: no-repeat;
                
                height: 6px;
                width: 30%;
                left: 0;
                bottom: 10px;
            }
        }
        p {
            line-height: 28px;
        }
    }

    
}

@media (min-width: $screen-sm-min) {
    #klachten {
        .content-top-wrapper  {
            h4 {
                font-size: 43px;
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #klachten {
        h2 {
            font-size: 28px;
           

            &.title {
                font-size: 55px;
            }
        }

        .content-top {
            background: url('/img/bg-wie-zijn-wij2.png') no-repeat top right;
            background-size: 100%;
        }

        .content-top-wrapper {

            margin: 50px 0;
            h4 {
                
                margin: 40px 20px 40px 0;
                top: 50%;
            }
        }

       
    }
}

@media (min-width: $screen-lg-min) {
    #klachten {
        .background-wrapper {
            background-position: center;
            height: 715px;

            p {
                font-size: 18px;
            }

            h2 {
                margin-top: 120px;
                color: $paars;
            }
        }
    }
}