#cookiebox {
    > .cookies,
    > .settings {
        position: fixed;
        bottom: 30px;
        right: -630px;
        max-width: 600px;
        width: calc(100% - 60px);
        padding: 30px;
        border-radius: 10px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        z-index: 10;
        font-size: 14px;
        overflow: hidden;
        transition: all 700ms ease;
        background: #FFF url('/img/bg_cookie.png') right top no-repeat;
        background-size: 100px;


        img {
            width: 120px;
        }

        h2 {
            margin-top: 0;
        }

        p {
            font-size: 16px;
            line-height: 20px;
        }

        .line {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 5px;
            width: 100%;
            background: linear-gradient(-45deg, $blauw_licht, $blauw);
        }

        .btn {
            margin: 20px 0 0 0;
            width: 100%;
        }
    }

    > .settings {
        form {
            label {
                display: block;
                margin-top: 15px;
                font-weight: normal;

                input {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;

                    height: 15px;
                    width: 15px;
                    margin-right: 3px;
                    min-height: auto;
                    padding: 0;
                    display: inline-block;
                    border-radius: 50%;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                    outline: 0;

                    -webkit-transform: translateY(2px);
                    -moz-transform: translateY(2px);
                    transform: translateY(2px);

                    -webkit-transition: all 300ms ease;
                    -moz-transition: all 300ms ease;
                    transition: all 300ms ease;

                    &:before,
                    &:after {
                        content: ' ';
                        position: absolute;
                        height: 3px;
                        background: #FFF;
                        border-radius: 2px;

                        -webkit-transition: all 300ms ease;
                        -moz-transition: all 300ms ease;
                        transition: all 300ms ease;
                    }

                    &:before {
                        top: 6px;
                        left: 4px;
                        width: 8px;

                        -webkit-transform: rotate(-45deg);
                        -moz-transform: rotate(-45deg);
                        transform: rotate(-45deg);
                    }

                    &:after {
                        width: 5px;
                        top: 7px;
                        left: 3px;

                        -webkit-transform: rotate(45deg);
                        -moz-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }

                    &:checked {
                        background: linear-gradient(-45deg, $blauw_donker, $blauw);
                    }
                }

                .title {
                    font-size: 14px;
                    font-weight: bold;
                }

                .label {
                    position: static;
                    color: #FFF;
                    padding: 3px 5px;
                    font-size: 10px;
                    background: #090;
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    #cookiebox {
        > .cookies,
        > .settings {
            .btn {
                margin-right: 30px;
                width: auto;
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #cookiebox {
        > .cookies,
        > .settings {
            background-size: auto;

            p {
                font-size: 20px;
                line-height: 1.8;
                padding-right: 30px;
            }

            img {
                width: 170px;
                margin-bottom: 20px;
            }

            form {
                label {
                    font-size: 18px;

                    .title {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}