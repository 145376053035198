* {
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
}

.container-fluid {
    margin: 0 auto;
    max-width: 1440px;
    position: relative;
}

.container {
    margin: 0 auto;
    max-width: 1440px;
}

a {
    color: $blauw_donker;
    text-decoration: underline;
    transition: all 300ms ease;

    &:hover {
        color: $blauw;
    }
}

p {
    line-height: 36px;
}

h1, h2, h3, h4 {
    margin-top: 0;
    margin-bottom: 0;
    font-family: 'Nunito', sans serif;
    color: $blauw_donker;
}

h2 {
    font-size: 2em;
    margin-top: 20px;
    margin-bottom: 20px;
}

.no-padding {
    padding: 0;
}

.clearfix {
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}

.greendot{
    height: 8px;
    width: 8px;
    background-color: $groen;
    border-radius: 50%;
    display: inline-block;
    margin: 0 3px;
}