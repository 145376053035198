#werkwijze {
    .no-mar {
        h2,
        h4 {
            margin-bottom: 0;

        }

        p {
            margin-top: 0;
        }
    }

    h2 {
        color: $blauw_donker;

        &.title {
            position: relative;
            padding-bottom: 40px;

            &:after {
                content: ' ';
                position: absolute;
                border-radius: 2px;
                background-image: url('/img/dotted1.png');
                background-repeat: no-repeat;

                height: 6px;
                width: 30%;
                left: 0%;
                bottom: 10px;
            }

            &.center {
                text-align: center;

                &:after {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    h3 {
        font-size: 30px;
        margin-bottom: 0;
    }

    .content-top-wrapper {
        h4 {
            color: $groen;
            font-size: 28px;
        }
    }

    .power {
        background: url('/img/bg-vorm-blauw.jpg') no-repeat center center;
        background-size: cover;
        text-align: center;
        padding: 30px 0;

        h2,
        p {
            color: #fff;
        }

        h2 {
            position: relative;
            padding-bottom: 40px;
            font-size: 35px;

            &:after {
                content: ' ';
                position: absolute;
                height: 5px;
                background: $blauw_licht;
                border-radius: 2px;
                width: 30%;
                left: 50%;
                bottom: 10px;
                transform: translateX(-50%);
            }
        }

        p {
            line-height: 28px;
        }
    }

    .regres {
        padding: 30px 15px 30px 70px;
        background: url('/img/bg-regres.jpg') no-repeat center center;
        background-size: cover;

        h2 {
            position: relative;
            padding-bottom: 40px;
            font-size: 35px;

            &:after {
                content: ' ';
                position: absolute;
                height: 5px;
                background: $blauw_licht;
                border-radius: 2px;
                width: 30%;
                left: 0;
                bottom: 10px;
            }
        }
    }

    .protocol {
        padding: 10px 0;
        background: $blauw;

        p {
            margin: 0;
            color: #fff;
            text-transform: uppercase;
            font-size: 20px;
            text-align: center;
        }
    }

    .track-record {
        padding: 50px 0;
        font-size: 24px;

        h2 {
            display: block;
            max-width: 600px;
            margin: 0 auto;
            font-size: 35px;
        }

        .intro {
            text-transform: uppercase;
            color: $blauw;
        }

        p {
            font-size: 16px;
            line-height: 28px;
        }
    }

    .background-wrapper {
        position: relative;
        background: url('../img/werkwijze-foto.jpg') no-repeat top left;
        background-size: cover;
        max-height: 550px;
    }


    .werkwijze-wrapper {
        background: url('../img/bg_werkwijze2.jpg') no-repeat bottom center;
        background-size: cover;
        text-align: center;
        color: #fff;

        h2 {
            position: relative;
            color: #fff;
            padding-bottom: 40px;

            &:after {
                content: ' ';
                position: absolute;
                border-radius: 2px;
                background-image: url('/img/dotted2.png');
                background-repeat: no-repeat;

                height: 6px;
                width: 30%;
                left: 40%;
                bottom: 10px;
            }
        }

        .pic-blok {
            margin: 0px 0 40px;
            border-radius: 25px;
            padding: 20px;
            background: #fff;
            min-height: 320px;

            .icon-wrapper {
                padding-top: 20px;
                color: $blauw_licht;
                font-size: 80px;
            }

            img {
                width: auto;
                height: 110px;
            }

            .pic-text {
                font-size: 18px;
                color: #000;
            }
        }

        .btn-blue {
            margin-bottom: 30px;
        }
    }
}

@media (min-width: $screen-sm-min) {
    #werkwijze {
        .content-top-wrapper {
            h4 {
                font-size: 43px;
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #werkwijze {
        h2 {
            font-size: 28px;


            &.title {
                font-size: 55px;
            }
        }

        .content-top {
            background: url('/img/bg-wie-zijn-wij2.png') no-repeat top right;
            background-size: 100%;
        }

        .content-top-wrapper {

            margin: 50px 0;

            h4 {

                margin: 40px 20px 40px 0;
                top: 50%;
            }
        }

        .power {
            h2 {
                font-size: 55px;
            }

            p {
                line-height: 36px;
            }
        }

        .regres {
            padding: 100px 0;

            h2 {
                font-size: 55px;
            }
        }

        .protocol {
            p {
                font-size: 30px;
                margin-right: 15px;
            }
        }

        .track-record {
            p {
                font-size: inherit;
                line-height: 36px;
            }
        }
    }
}

@media (min-width: $screen-lg-min) {

    #werkwijze {
        .werkwijze-wrapper {
            h2 {
                margin-top: 50px;
                margin-bottom: 30px;
                font-size: 58px;
                color: #fff;
            }

            .subtext {
                margin: 0 200px 80px;
            }

            p {
                font-size: 18px;
            }

            .pic-blok {
                // padding: 0 40px 50px;
            }

            .btn-blue {
                margin-bottom: 45px;
            }
        }

        .background-wrapper {
            background-position: center;
            height: 715px;

            p {
                font-size: 18px;
            }

            h2 {
                margin-top: 120px;
                color: $paars;
            }
        }

    }

}