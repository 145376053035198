.btn-pink {
    appearance: none !important;
    border: none !important;
    background-color: $roze;
    color: #FFF;
    padding: 8px 20px;
    border-radius: 30px;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    display: block;
    box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.4);
    text-decoration: none;

    &:hover {
        color: #fff;
    }
}

.btn-blue {
    appearance: none !important;
    border: none !important;
    background-color: $blauw_donker;
    color: #FFF;
    padding: 8px 20px;
    border-radius: 30px;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    display: block;
    box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.4);
    text-decoration: none;

    &:hover {
        color: #fff;
    }
}

.btn-green {
    appearance: none !important;
    border: none !important;
    background-color: $blauw_donker;
    color: #FFF;
    padding: 8px 30px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    display: block;
    text-decoration: none;

    &:hover {
        color: #fff;
    }
}

@media (min-width: $screen-sm-min) {
    .btn-pink {
        display: inline-block;
        padding: 12px 30px;
        font-size: 20px;
    }

    .btn-blue {
        display: inline-block;
        padding: 12px 30px;
        font-size: 20px;
    }
}

@media (min-width: $screen-lg-min) {
    .btn-pink {
        padding: 15px 40px;
        border-radius: 50px;
    }

    .btn-blue {
        padding: 15px 40px;
        border-radius: 50px;
    }
}