#verzuim {
    .no-mar {
        h2,
        h4 {
            margin-bottom: 0;

        }

        p {
            margin-top: 0;
        }
    }

    h2 {
        color: $blauw_donker;

        &.title {
            position: relative;
            padding-bottom: 40px;

            &:after {
                content: ' ';
                position: absolute;
                border-radius: 2px;
                background-image: url('/img/dotted1.png');
                background-repeat: no-repeat;

                height: 6px;
                width: 30%;
                left: 0%;
                bottom: 10px;
            }

            &.center {
                text-align: center;

                &:after {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    h3 {
        font-size: 30px;
        margin-bottom: 0;
    }

    .content-top-wrapper {
        h4 {
            color: $groen;
            font-size: 28px;
        }
    }

    .power {
        background: url('/img/bg-vorm-blauw.jpg') no-repeat center center;
        background-size: cover;
        text-align: center;
        padding: 30px 0;

        h2,
        p {
            color: #fff;
        }

        h2 {
            position: relative;
            padding-bottom: 40px;
            font-size: 35px;

            &:after {
                content: ' ';
                position: absolute;
                height: 5px;
                background: $blauw_licht;
                border-radius: 2px;
                width: 30%;
                left: 50%;
                bottom: 10px;
                transform: translateX(-50%);
            }
        }

        p {
            line-height: 28px;
        }
    }

    .regres {
        padding: 30px 15px 30px 70px;
        background: url('/img/bg-regres.jpg') no-repeat center center;
        background-size: cover;

        h2 {
            position: relative;
            padding-bottom: 40px;
            font-size: 35px;

            &:after {
                content: ' ';
                position: absolute;
                height: 5px;
                background: $blauw_licht;
                border-radius: 2px;
                width: 30%;
                left: 0;
                bottom: 10px;
            }
        }
    }

    .protocol {
        padding: 10px 0;
        background: $blauw;

        p {
            margin: 0;
            color: #fff;
            text-transform: uppercase;
            font-size: 20px;
            text-align: center;
        }
    }

    .track-record {
        padding: 50px 0;
        font-size: 24px;

        h2 {
            display: block;
            max-width: 600px;
            margin: 0 auto;
            font-size: 35px;
        }

        .intro {
            text-transform: uppercase;
            color: $blauw;
        }

        p {
            font-size: 16px;
            line-height: 28px;
        }
    }

    .background-wrapper {
        position: relative;
        background: url('../img/foto-verzuim.jpg') no-repeat top left;
        background-size: cover;
        max-height: 550px;
    }


    .wet-wrapper {
        background: url('../img/bg-verzuim.jpg') no-repeat bottom center;
        background-size: cover;
        text-align: center;
        color: #fff;

        h2 {
            position: relative;
            color: #fff;
            padding-bottom: 40px;

            &:after {
                content: ' ';
                position: absolute;
                border-radius: 2px;
                background-image: url('/img/dotted2.png');
                background-repeat: no-repeat;

                height: 6px;
                width: 30%;
                left: 40%;
                bottom: 10px;
            }
        }

        ul.items {
            list-style: none;
            text-align: center;
            margin: 30px 0;
            padding: 0;

            li {
                display: inline-block;
                font-weight: bold;

                > a {
                    position: relative;
                    padding-bottom: 15px;
                    margin: 0 15px 30px 15px;
                    display: block;
                    font-family: 'Nunito', sans serif;
                    font-size: 28px;
                    color: #FFF;
                    text-decoration: none;

                    &:after {
                        content: ' ';
                        position: absolute;
                        left: 0;
                        bottom: 0px;
                        display: block;
                        width: 100%;
                        height: 5px;
                        background: $groen;
                    }
                }

                .popup {
                    position: fixed;
                    display: none;
                    left: 15px;
                    right: 15px;
                    top: 15px;
                    bottom: 15px;
                    z-index: 10;
                    background: #FFF;
                    border: 3px solid $groen;
                    padding: 25px;
                    overflow: scroll;
                    text-align: left;
                    box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.3);

                    .close {
                        display: block;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        position: fixed;
                        right: 15px;
                        top: 15px;
                        background: $groen;
                        color: #FFF;
                        text-align: center;
                        text-decoration: none;
                        font-size: 33px;
                    }

                    h2 {
                        color: $groen;
                        font-size: 28px;
                        margin: 0 0 15px 0;
                        padding: 0;

                        &:after {
                            content: none;
                        }
                    }

                    .popup_content {
                        color: #000;

                        p {
                            font-size: 15px;
                            font-weight: normal;
                            color: #000;
                            line-height: 1.5;
                            margin: 0 0 30px 0;
                        }


                        ul {
                            color: #000;
                            margin-bottom: 30px;

                            li {
                                position: relative;
                                display: block;
                                text-align: left;
                                font-size: 16px;
                                font-weight: normal;
                                color: #000;
                                padding-left: 15px;

                                &:before {
                                    content: ' ';
                                    position: absolute;
                                    left: 0;
                                    top: 12px;
                                    display: block;
                                    width: 6px;
                                    height: 6px;
                                    background: #000;
                                    border-radius: 3px;
                                }

                                ul {
                                    margin-top: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    #verzuim {
        .content-top-wrapper {
            h4 {
                font-size: 43px;
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #verzuim {
        h2 {
            font-size: 28px;


            &.title {
                font-size: 55px;
            }
        }

        .content-top {
            background: url('/img/bg-wie-zijn-wij2.png') no-repeat top right;
            background-size: 100%;
        }

        .content-top-wrapper {

            margin: 50px 0;

            h4 {

                margin: 40px 20px 40px 0;
                top: 50%;
            }
        }

        .power {
            h2 {
                font-size: 55px;
            }

            p {
                line-height: 36px;
            }
        }

        .regres {
            padding: 100px 0;

            h2 {
                font-size: 55px;
            }
        }

        .protocol {
            p {
                font-size: 30px;
                margin-right: 15px;
            }
        }

        .track-record {
            p {
                font-size: inherit;
                line-height: 36px;
            }
        }

        .wet-wrapper {
            ul.items {
                position: relative;

                li {
                    .popup {
                        position: absolute;
                        left: 50%;
                        right: auto;
                        top: auto;
                        bottom: 0px;
                        overflow: visible;
                        transform: translate(-50%, 100%);
                        width: calc(100% - 30px);
                        max-width: 1242px;
                        padding: 30px;

                        .close {
                            position: absolute;
                            right: -3px;
                            top: -3px;
                        }

                        .popup_content {
                            column-count: 2;
                            column-gap: 30px;

                            p, ul li {
                                font-size: 18px;
                                line-height: 1.8;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-lg-min) {

    #verzuim {
        .wet-wrapper {
            h2 {
                margin-top: 50px;
                margin-bottom: 30px;
                font-size: 58px;
                color: #fff;
            }

            ul.items {
                li {
                    > a {
                        margin: 0 30px 60px 30px;
                    }

                    .popup {
                        padding: 50px;
                        bottom: 30px;

                        .popup_content {
                            column-gap: 50px;
                        }
                    }
                }
            }
        }

        .background-wrapper {
            background-position: center;
            height: 715px;

            p {
                font-size: 18px;
            }

            h2 {
                margin-top: 120px;
                color: $paars;
            }
        }

    }

}