.carousel-wrapper {
    position: relative;

    .slide-wrapper {
        .slide {
            object-position: top;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }

            .container-fluid {
                .text-overlay {
                    position: absolute;
                    left: 15px;
                    top: 55%;
                    transform: translateY(-50%);
                    font-weight: 700;
                    font-family: 'Nunito', sans serif;

                    &::before {
                        display: none
                    }

                    h2 {
                        font-size: 28px;
                        color: #FFF;
                        padding-bottom: 20px;
                        margin: 0;

                        &:after {
                            content: ' ';
                            position: absolute;
                            left: 0;
                            bottom: 10px;
                            border-radius: 2px;
                            
                            background-size: cover;
                            width: 212px;
                            height: 6px;
                        }
                    }
                }
            }
        }
    }

    &.homepage {
        .slide-wrapper {
            .slide {
                .container-fluid {
                    .text-overlay {
                        h2 {
                            color: $paars;

                            &:after {
                                content: ' ';
                                position: absolute;
                                left: 0;
                                bottom: 10px;
                                border-radius: 2px;
                                background: url('/img/dotted1.png') left center no-repeat;
                                background-size: cover;
                                width: 212px;
                                height: 6px;
                            }
                        }
                    }
                }
            }
        }
    }

    .button-overlay {
        a {
            position: absolute;
            bottom: -20px;
            right: 50%;
            transform: translate(50%);
            z-index: 5;
            text-decoration: none;

            line-height: 40px;
            text-align: center;
            height: 40px;
            width: 40px;
            background-color: $groen;
            color: white;
            font-size: 15px;
            border-radius: 40px;
            box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.4);
        }
    }
}


@media (min-width: $screen-sm-min) {
    .carousel-wrapper {
        .slide-wrapper {
            .slide {
                .container-fluid {
                    .text-overlay {
                        h2 {
                            font-size: 32px;
                            padding-bottom: 40px;
                        }
                    }
                }
            }
        }

        .button-overlay {
            a {
                bottom: -30px;
                line-height: 60px;
                height: 60px;
                width: 60px;
                font-size: 19px;
                border-radius: 60px;
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    .carousel-wrapper {
        .slide-wrapper {
            .slide {
                .container-fluid {
                    .text-overlay {
                        h2 {
                            font-size: 40px;
                        }
                    }
                }
            }
        }

        &.contact {
            .slide-wrapper {
                .slide {
                    .text-overlay {
                        h2 {
                            font-size: 50px;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    .carousel-wrapper {
        .slide-wrapper {
            .slide {
                .container-fluid {
                    .text-overlay {
                        h2 {
                            font-size: 50px;
                        }
                    }
                }
            }
        }
    }

    .button-overlay {
        a {
            bottom: -7%;
            line-height: 80px;
            height: 80px;
            width: 80px;
            font-size: 30px;
            border-radius: 80px;
        }
    }
}

@media (min-width: 1500px) {
    .carousel-wrapper {
        .slide-wrapper {
            .slide {
                .container-fluid {
                    .text-overlay {
                        h2 {
                            font-size: 60px;
                        }
                    }
                }
            }
        }
    }
}