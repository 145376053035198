#contact {
    h2 {
        display: inline-block;
        color: $blauw_donker;

        &.title {
            position: relative;
            padding-bottom: 40px;
            padding-top: 30px;

            &:after {
                content: ' ';
                position: absolute;
                border-radius: 2px;
                background-image: url('/img/dotted1.png') ;
                background-repeat: no-repeat;
                
                height: 6px;
                width: 30%;
                left: 0;
                bottom: 10px;
            }

        }
        &.center {
            text-align: center;

            &:after {
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &.white {
            color: #fff;
        }
    }

    .bold {
        font-weight: 600;
    }

    .btn-blue {
        float: right;
        border-radius: 50px;
        -webkit-appearance: button-bevel;
        font-size: 20px;
    }

    form {
        @extend .clearfix;
        margin-bottom: 30px;
    }

    .background-wrapper {
        position: relative;
        background: url('../img/bg-contact.jpg') no-repeat top left;
        background-size: cover;
        max-height: 550px;
    }
}

@media (min-width: $screen-sm-min) {
    #contact {
        .background-wrapper {
            height: 450px;
        }
    }
}

@media (min-width: $screen-md-min) {
    #contact {
        h2 {
            margin-top: 20px;
            font-size: 58px;
        }
        .contact-wrapper {

            p {
                font-size: 18px;

                &.bold {
                    font-weight: 600;
                    margin-top: 0;
                }
            }

            form {
                margin-top: 10px;
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    #contact {
        .background-wrapper {
            background-position: center;
            height: 715px;

            p {
                font-size: 18px;
            }

            h2 {
                margin-top: 120px;
                color: $paars;
            }
        }
    }
}